<template>
  <div class="detailsWrap">
    <div class="details content">
      <div class="title">{{newsContent.subject}}</div>
      <div class="top">
        <i class="el-icon-back back" @click="$router.back(-1)"></i>
        <div class="timer">发布时间：{{format(newsContent.createdAt)}}</div>
      </div>
      <div class="newsDetails" v-html="newsContent.content"></div>
    </div>
  </div>
</template>
<script>
import url from "@/api/url.js";
export default {
  data() {
    return {
      newsID: "",
      newsContent: {},
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.newsID = this.$route.query.id;
      this.$axios.get(url.newsDetails + `${this.newsID}`).then((res) => {
        this.newsContent = res.new;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.detailsWrap {
  padding: 60px 0;
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    min-height: 40px;
  }
  .top{
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .back{
      font-size: 28px;
      display: block;
      cursor: pointer;
      color: #999;
    }
  }
  .timer {
    color: #666;
    font-size: 16px;
    color: #999;
  }
  .newsDetails {
    line-height: 40px;
  }
}
</style>